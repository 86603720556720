import React from "react"
import { Link } from "gatsby"

import gpowell from "../images/gpowell.png"
import wpowell from "../images/wpowell.png"

import foodgath from '../images/FG@2x.png'
import comcast from '../images/Comcast@2x.png'
import fli from '../images/FLI@2x.png'
import h4h_logo from '../images/H4H-logo@2x.png'
import lf_logo from '../images/LF-logo@2x.png'
import sheriff from '../images/Shreriff@2x.png'
import UofM_health from '../images/UofM_health@2x.png'
import washtenaw_lit from '../images/Washtenawliteracy@2x.png'
import wcparc from '../images/WCPARC_COLOR_logo@2x.png'
import ycs from '../images/YCS@2x.png'
import UofM_Med from '../images/uofmMed.png'


const mobileAccNav = () => (


 <ul data-uk-accordion>
    <li >
      <h3 className='uk-accordion-title'>
        Who We Are
      </h3>
      <div className='uk-accordion-content'>
        <div >
                       <h1>What We do</h1>
                       <p style={{color: '#2D4B65' }}><b>Mission</b> CFLC is a neighborhood-based, non-profit organization that serves as a hub for the Sugarbrook Neighborhood and the greater Ypsilanti area. Our mission is to serve individuals, youth and their families with programming focused on social, educational, physical and economic well-being.  
                       </p>
                       <p style={{color: '#2D4B65' }}>
                        <b>Vision</b> Our vision is to enhance the quality of life and fill the unmet needs of our community.
                    </p>

                </div>
      </div>
    </li>
    <li>
      <h3 className='uk-accordion-title'>
        Founders
      </h3>
      <div className='uk-accordion-content'>
        <h1>Founders</h1>
                        
                        <div class="texthelp uk-child-width-1-1 uk-flex uk-text-small flexdirection" style={{color: '#2D4B65' }} uk-grid>
                       <div class="uk-padding">
                                <img src={wpowell}  />
                                <h3 style={{color: '#E5901A' }} >Willie Powell </h3>
                                <p>Willie is the founder and Executive Director of 
                                    Community Family Life Center.
                                    He was born and raised in Ypsilanti and received 
                                    his K-12 education within the
                                    Ypsilanti Public School System. Willie is a 
                                    graduate from The University of
                                    Michigan and holds a degree in Business 
                                    Administration.
                                </p>
                                <p>Willie brings over 25 years of experience in 
                                    fiscal management, accounting,
                                    and auditing. Through his work at the City of 
                                    Ann Arbor, Willie served within
                                    the Department of Community Development 
                                    and was given the opportunity to
                                    come alongside non-profit organizations to help 
                                    strengthen their financial
                                    capacity. In addition, he has served as the 
                                    Executive Director of the City of Ann
                                    Arbor Employees’ Retirement System.
                                </p>
                                <p>Willie believes that strong community 
                                    partnerships are the catalyst to bringing
                                    about social and economic change within low-
                                    resourced communities.
                                    Therefore, he is passionate about collaborating 
                                    with community leaders and
                                    partners to develop programs for the adults, 
                                    children, and families who live in
                                    the Sugarbrook and surrounding communities.
                                </p>
      						</div>
      						<div  class="uk-padding">
                            <img src={gpowell} />
                            <h3 style={{color: '#E5901A' }} >Geraldine Powell</h3>
                            <p>Geraldine is the co-founder of Community 
                                Family Life Center and is a certified personal 
                                trainer and fitness coach. She was born and 
                                raised in Detroit, Michigan and attended 
                                Detroit Public Schools.  She holds a degree in 
                                communications and accounting from Eastern 
                                Michigan University. 
                            </p>
                            <p>
                            Geraldine has worked with children, youth, and 
                            families for over 30 years. She has served as the 
                            Executive Director of Wee Wisdom Early 
                            Childhood Center and has coordinated 
                            numerous after school, summer, and nutritional 
                            programs for children and youth. Geraldine 
                            brings a wealth of knowledge regarding the 
                            educational, nutritional, social-emotional, 
                            health, and physical development of children. In 
                            addition, she is the founder of Lifestyle Fitness 
                            Coach, LLC and holds numerous certifications in 
                            fitness and nutrition. 
                            </p>
                            <p>
                            Geraldine is passionate about supporting the 
                            holistic well-being of adults, children, and 
                            families. She pursues opportunities to teach 
                            proper nutrition, wellness, and fitness to adults, 
                            children and families in Sugarbrook and its 
                            surrounding communities.
                            </p>


                       
                       </div>
      </div>
      </div>
    </li>

    <li>
      <h3 className='uk-accordion-title'>
        Board of Directors
      </h3>
      <div className='uk-accordion-content'>
         <h1>Board of Directors</h1>
                       <ul class="uk-list " style={{color: '#2D4B65' }}>
                            <li class="uk-padding-small">
                            Theresa Beasley<br/>
                            Position: Chairperson

                            </li>
                            <li class="uk-padding-small">
                            Jeff Bennett<br/>
                            Position: Treasury

                            </li>
                            <li class="uk-padding-small">
                            Louis Andrews<br/>
                            Position: Trustee

                            </li>
                            <li class="uk-padding-small">
                            Andrew Robinson<br/>
                            Position: Trustee

                            </li>
                            <li class="uk-padding-small">
                            Hourris Little<br/>
                            Position: Trustee

                            </li>
                        </ul>

      </div>
    </li>
     <li>
      <h3 className='uk-accordion-title'>
        Partners
      </h3>
      <div className='uk-accordion-content'>
        <h1>Partners</h1>
                            <ul  class="inliBlock uk-list">    
                                <li>
                                    <div style={{float: "left", width: "20%", height: "200px"}} class="uk-margin-right">
                                    <img src={comcast} />
                                    </div>
                                    <h4> COMCAST COMMUNITY IMPACT</h4>
                                    <p> Comcast Community Impact provides backpacks to CFLC to be 
}
handed out for free to the Ypsilanti School children.</p>


                                </li>
                                <li>
                                        
                                       <img src={ycs}  width="20%" class="uk-float-left"/>
                                    <h4> Erickson Elementary School</h4>
                                    <p> CFLC partners with Erikson Elementary School and Family Learning 
                                        Institute to provide a physical space for their after-school, tutoring 
                                        program. CFLC also provides support in gathering and storing equipment 
                                        and supplies necessary for the program. 
                                    </p>

                                </li>
                                <li>
                                        <div style={{float: "left", width: "20%", height: "400px"}}>
                                       <img src={h4h_logo} />
                                       </div>
                                    <h4> Habitat for Humanity</h4>
                                    <p> 
                                        Habitat for Humanity of Huron Valley’s Community Development 
                                        Team works alongside CFLC to guide a 
                                        long-term, resident-led neighborhood planning process. Since 2016 
                                        we have been providing opportunities for residents of Sugarbrook to 
                                        come together to express their gifts, dreams, and concerns for their 
                                        community. Beginning in January 2019 we have been supporting 
                                        Sugarbrook Action Teams focused on resident-identified issues of
                                        Youth Activities, Amenities, Neighbor Relations, and Kettering School 
                                        Site. CFLC provides wonderful meeting space for small and large meetings 
                                        as well as trusted community leadership and knowledge. 
                                    </p>

                                </li>
                                <li>
                                        
                                       <img src={fli} style={{float:"left"}} width="20%"/>
                                    <h4> Family Learning Institute</h4>
                                    <p> 
                                        The Family Learning Institute is a volunteer-based nonprofit agency 
                                        dedicated to closing the achievement gap in Washtenaw County.  We 
                                        provide free tutoring in the areas of math and reading for students in 
                                        grades 2-5 who are from economically disadvantaged families and are 
                                        struggling in school. CFLC partners with Erikson Elementary School and Family Learning Institute to provide a
physical space for their after-school, tutoring program. 
                                    </p>

                                </li>
                                <li>
                                        
                                       <img src={foodgath} style={{float:"left"}} width="20%"/>
                                    <h4> Food Gatherers</h4>
                                    <p> 
                                        Since 2012, Food Gatherers has partnered with CFLC to coordinate 
                                        volunteers, organize, and distribute free food to qualifying residents living 
                                        in the Sugarbrook and surrounding communities.  The Food Pantry at CFLC 
                                        currently distributes food to 120-130 families monthly.
                                    </p>

                                </li>
                                <li>
                                        
                                       <img src={UofM_Med} style={{float:"left"}} width="20%"/>
                                    <h4> University of Michigan Community Programs and Services</h4>
                                    <p> 
                                        The University of Michigan partners with CFLC  to provide the Sugarbrook 
                                        Community with health and wellness activities. The University of Michigan, 
                                        Community Health Services Department (CHS) partners with CFLC to provide health 
                                        and wellness activities to the Sugarbrok Community. CFLC has also received grant 
                                        funding support for the Promoting Healthy Families Project.

                                </p>
                                </li>
                                <li>
                                        
                                       <img src={wcparc} style={{float:"left"}} width="20%"/>
                                    <h4> Washtenaw County Parks and Recreation</h4>
                                    <p> 
                                        Washtenaw County Parks and Recreation Department use CLFC indoor and 
                                        outdoor facilities during the summer months to host its Playground 
                                        Program for youth in the surrounding areas.
                                    </p>

                                </li>
                                <li>
                                        
                                       <img src={sheriff} style={{float:"left"}} width="20%"/>
                                    <h4> Washtenaw County Sheriff Department of Community Engagement</h4>
                                    <p> 
                                        CFLC partners with the Washtenaw County Sheriff Engagement Program 
                                        to provide recreational activities to at risk youths in the community.
                                    </p>

                                </li>
                                <li>
                                        
                                       <img src={washtenaw_lit} style={{float:"left"}} width="20%"/>
                                    <h4> Washtenaw Literacy</h4>
                                    <p> 
                                        CFLC provides a private space within our facilities to Washtenaw Literacy 
                                        where they provide an adult literacy tutoring program. CFLC and 
                                        Washtenaw Literacy collaborated in their pursuit of a grant awarded 
                                        Comcast Cable to purchase the necessary equipment to support adults 
                                        seeking to improve their computer literacy.
                                    </p>

                                </li>





                            </ul>
                       
      </div>
    </li>
  </ul>





	)



export default mobileAccNav