import React from "react"
import { Link } from "gatsby"
import SecHeader from "../components/secondHeader"
import SideNav from "../components/sideNav"
import Layout from "../components/layout"
import MobileAccNav from "../components/mobileAccNav"
import SEO from "../components/seo"
import Logo from "../images/webLogo.png"
import gpowell from "../images/gpowell.png"
import wpowell from "../images/wpowell.png"

import foodgath from '../images/FG@2x.png'
import comcast from '../images/Comcast@2x.png'
import fli from '../images/FLI@2x.png'
import h4h_logo from '../images/H4H-logo@2x.png'
import lf_logo from '../images/LF-logo@2x.png'
import sheriff from '../images/Shreriff@2x.png'
import UofM_health from '../images/UofM_health@2x.png'
import washtenaw_lit from '../images/Washtenawliteracy@2x.png'
import wcparc from '../images/WCPARC_COLOR_logo@2x.png'
import ycs from '../images/YCS@2x.png'
import aboutus from '../images/AboutUs.jpg'




const AboutUs = () => (
  <Layout>
  	<SecHeader pageTitle="About Us" image={aboutus} />
    <SEO title="About Us" />
        
    <div class="uk-padding uk-section ">

        <div class="uk-visible@m">
        <SideNav /> 
        </div>

        <div class="uk-hidden@m">
        <MobileAccNav />
        </div>

       
            
              

             
   
            
       

    </div>
   
  </Layout>
)

export default AboutUs